import * as React from "react"
import "../styles/index.scss"
import Header from "./header"
import Footer from "./footer"
import SimpleReactLightbox from 'simple-react-lightbox'

if (typeof window !== 'undefined'){
  document.addEventListener("contextmenu", function(e){
    if (e.target.nodeName === "IMG") {
        e.preventDefault();
    }
  }, false);
}

const Layout = ({children}) => {
  return (
    <>
        <Header />
        <main className="container">
          <SimpleReactLightbox>
            {children}
          </SimpleReactLightbox>
        </main>
        <Footer />
    </>
  )
}

export default Layout
