import React from 'react';
import {Link} from 'gatsby';

import {FaImdb, FaLinkedin, FaInstagram} from "react-icons/fa"

const socials = [
  {
    "name" : "IMDB",
    "url"  : "https://www.imdb.com/name/nm1444691/",
    "icon" : FaImdb
  },
  {
    "name" : "Instagram",
    "url"  : "https://www.instagram.com/matt.t.parsons/",
    "icon" : FaInstagram
  },
  {
    "name" : "LinkedIn",
    "url"  : "https://www.linkedin.com/in/matthew-parsons-4435a68a/",
    "icon" : FaLinkedin
  }
]

const Footer = () => {

    return(
    <>
        <div style={{boxShadow:"inset 0px 9px 9px -14px #111",backgroundColor:'white',fontFamily:'Bebas Neue'}} className="ptb--10">
            <div className="container">
                <div className="row footer">
                    <div style={{alignSelf:'center'}} className="col-12 col-md-4 hover">
                        <Link style={{color:'black'}} to="/about-me">About</Link><br />
                        <Link style={{color:'black'}} to="/#portfolio">Portfolio</Link><br />
                        <Link style={{color:'black'}} to="/contact">Contact</Link>
                    </div>
                    <div style={{textAlign:'center',alignSelf:'center'}} className="col-12 col-md-4">
                        {socials.map((social, i) => (
                        <div style={{display:'inline',padding:'15px',width:'100%'}} key={i}>
                            <a href={social.url} target="_blank" rel="noreferrer">
                                <social.icon style={{ color: 'black', fontSize: '32px' }} />
                            </a>
                        </div>
                        ))}
                    </div>
                    <div style={{textAlign:'right',alignSelf:'center'}} className="col-12 col-md-4">
                        <div className="credits">
                            © {new Date().getFullYear()}{` - `}Matt Parsons <br />
                        </div>
                        <div style={{fontSize:'10px',fontFamily:'sans-serif'}} className="hover credits">
                            made by <a style={{color:'black'}} href="https://totel.it" target="_blank" rel="noreferrer">totel.it</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default Footer