import React, {useState} from "react"
import MediaQuery from 'react-responsive'
import {Link} from "gatsby"
import { Twirl as Hamburger } from 'hamburger-react'

const Header = () => {
  function menux(){
    setOpen(0);
  }
  const [isOpen, setOpen] = useState(false)
  const isBrowser = typeof window !== "undefined"
  if(isBrowser){
    isOpen ? document.documentElement.style.overflow = 'hidden' : document.documentElement.style.overflow = 'unset'
  }
  return(
    <div style={{boxShadow:"#00000040 0px 1px 7px"}}>
      <div className="container" style={{display:'flex',justifyContent:'space-between',position:'relative',height:'100px',width:'100%',top:'0',zIndex:'9999'}}>
        <div style={{objectFit:'contain',alignSelf:'center',cursor:'pointer'}}>
          <Link to={'/'}>
            <h1 style={{marginBottom:'0.2rem',fontSize:'24px',color:'black',textTransform:'uppercase',textDecoration:'none'}}>Matt Parsons</h1>
          </Link>
        </div>
        <MediaQuery minWidth={1224}>
        <div className="header" style={{objectFit:'contain',alignSelf:'center'}}>
          <Link to="/about-me">
            <button onClick={() => menux()}>
              About
            </button>
          </Link>
          <Link to="/#portfolio">
            <button onClick={() => menux()}>
              Portfolio
            </button>
          </Link>
          <Link to="/contact">
            <button onClick={() => menux()}>
              Contact
            </button>
          </Link>
        </div>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
        <div className="header" style={{objectFit:'contain',alignSelf:'center'}}>
          <Hamburger toggled={isOpen} toggle={setOpen} />
        </div>
        {isOpen ? 
            <div style={{backgroundColor:'white',position:'absolute',width:'100vw',height:'100vh',marginLeft:'50%',transform:'translateX(-50%)'}}>
              <div className="inner-menu">
                <Link to="/about-me">
                  <button onClick={() => menux()}>
                    About
                  </button>
                </Link>
                <Link to="/">
                  <button onClick={() => menux()}>
                    Portfolio
                  </button>
                </Link>
                <Link to="/contact">
                  <button onClick={() => menux()}>
                    Contact
                  </button>
                  </Link>
              </div>
            </div>
            :
            null
          }
        </MediaQuery>
      </div>
    </div>
  )
}
export default Header
